#root {
  font-family: "Open Sans", sans-serif;
  height: 100%;
  width: 70%;
  text-align: center;
  background-color: #ffffff;
  margin: 0 auto;
}

.App-header {
  background-color: #ffffff;
  padding: 10px;
  text-align: center;
  font-size: 25px;
  color: #101820;
  font-family: "Open Sans", sans-serif;
}
.title-desktop{
  display: block;
  margin-top: 0;
  text-align: left;
}
.title-mobile{
  display: none;
}

/* Layout styling */
.row {
  display: flex;
  width: 100%;
  border-bottom: 5px solid #f1f1f1;
}
.row:target, .anchor{
  border:2px dashed #bdbdbd;
}
.column0 {
  background-color: #ffffff;
  -webkit-flex: 30%;
  -ms-flex: 30%;
  flex: 30%;
  margin: 5px;
}

.column1 {
  background-color: #ffffff;
  -webkit-flex: 70%;
  -ms-flex:70%;
  flex: 70%;
  margin: 5px;
}

.column2 {
  background-color: #ffffff;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 5px;
}

footer {
  background-color: #ffffff;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #101820;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  padding: 10px 0 10px;
}
.logo img {
  width: 250px;
}
.Img {
  display: block;
  margin: 5px auto;
  position: relative;
  height: auto;
  overflow: hidden;
}

img {
  width: 100%;
  height: auto;
}

.description-column strong {
  font-weight: normal;
}

.description-column p {
  text-align: left;
}

.description-column a {
  color: red;
}
.description {
  text-align: left;
  font-weight: bolder;
}
.title-desktop a, .title-mobile a {
  text-decoration: none;
  font-size: x-small;
  color: red;
}
.column1 ul{
  text-align: left;
}


/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media (max-width: 800px) {
  .row {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .title-desktop{
    display: none;
  }
  .title-mobile{
    display: block;
    margin-top: 0;

  }
}
@media (max-width: 400px){
  .logo img{
    width: 100%;
  }
}